<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <titlebar :name="name" :is_custom_back="true" @goBackCustom="goBlack" />
    <div class="search_box">
      <div class="choose" @click="time">
        {{ timevl || "请选择日期" }}
        <!-- <img src="@/static/select.png" alt="" /> -->
      </div>
      <input
        type="text"
        class="search"
        placeholder="请输入员工工号"
        v-model="staff_account"
      />
      <div class="search_btn" @click="search()">搜索</div>
      <div class="reset_btn" @click="reset()">重置</div>
    </div>
    <div class="List_box bg_f8">
      <div class="box_wh">开始时间</div>
      <div class="box_wh">结束时间</div>
      <div class="box_wh">收银员</div>
      <div class="box_wh">收银总额</div>
      <!-- <div class="list_num">库存变动</div>
      <div class="list_people">操作人</div>
      <div class="list_time">创建时间</div> -->
    </div>
    <div class="list_page">
      <div class="List_box" v-for="(item, index) in progresslist" :key="index">
        <div class="box_wh">{{ item.s_time }}</div>
        <div class="box_wh">{{ item.e_time }}</div>
        <div class="box_wh">{{ item.staff }}</div>
        <div class="box_wh">￥{{ item.total_price }}</div>
      </div>
    </div>
    <!-- 底部合计 -->
    <div class="total">
      <van-col span="12">
        <div style="width: fit-content">
          <van-pagination
            v-model="page"
            :total-items="total"
            :items-per-page="limit"
            @change="pageChange"
            force-ellipses
          />
        </div>
      </van-col>
      <div class="total_prc">
        总计收银总额：<span>¥{{ total_price }}</span>
      </div>
    </div>
    <!-- 日期选择器 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="this.maxDate"
        @confirm="confirmFn()"
        @cancel="cancel()"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import titlebar from "@/views/component/titlebar";
export default {
  created() {
    this.name = this.$route.query.name; //拿到传过来的标题
    console.log("name:", this.name);
    this.getList();
  },
  components: {
    titlebar: titlebar,
  },
  data() {
    return {
      name: "",
      value: "",
      show: false, // 时间弹窗
      timevl: undefined, //日期选择
      staff_account: undefined, //员工账号筛选
      minDate: new Date(2020, 10, 1),
      maxDate: new Date(2200, 10, 1),
      changeDate: new Date(),
      currentDate: new Date(),
      page: 1, //页数
      limit: 10, //条数
      total: 0,
      progresslist: [], //交班记录表
      total_price: "", //总价
    };
  },
  methods: {
    goBlack() {
      this.$router.replace({
        name: 'featureSet',
        query: {
          name: this.$route.query.name,
          active: this.$route.query.active,
        }
      })
    },
    // 筛选
    search() {
      if(this.timevl == '') {
        this.timevl = undefined;
      }
      if(this.staff_account == '') {
        this.staff_account = undefined;
      }
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
        s_time: this.timevl,
        staff_num: this.staff_account,
      };
      this.$api.StaffHandover(data).then((res) => {
        this.progresslist = res.data.list;
      });
    }, 
    // 重置
    reset() {
      this.limit = this.limit;
      this.page = this.page;
      this.timevl = undefined;
      this.staff_account = undefined;

      this.getList();
    },
    // 获取交接班记录列表
    getList() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
      };
      this.$api.StaffHandover(data).then((res) => {
        this.progresslist = res.data.list;
        this.total = res.data.count;
        this.total_price = res.data.total_price;
      });
    },
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("销售商品列表，当前页码：", this.page);
      this.getList();
    },
    operate() {
      this.showcz = true;
    },
    onChange(picker, value, index) {
      //   Toast(`当前值：${value}, 当前索引：${index}`);
    },
    // 日期选择开启
    time() {
      this.show = true;
    },
    // 时间选择器:当值变化时触发的事件
    change() {
      this.changeDate = this.currentDate; // Tue Sep 08 2020 00:00:00 GMT+0800 (中国标准时间)
    },
    // 时间选择器完成
    confirmFn() {
      this.timevl = this.timeFormat(this.currentDate);
      this.show = false;
      console.log("日期选择器完成:", this.timevl);
    },
    // 时间选择器取消
    cancel() {
      this.show = false;
    },
    timeFormat(time) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      return year + "-" + month + "-" + day;
    },
  },
  mounted() {
    this.timeFormat(new Date());
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // align-items: center;
  .search_box {
    border-top: 0.1rem solid #e5e5e5;
    padding-top: 3rem;
    display: flex;
    // flex-direction: column;
    align-items: center;
    margin-left: 5.2rem;
    margin-bottom: 3.7rem;
    .search {
      width: 23rem;
      height: 8rem;
      border-radius: 0.8rem;
      background: #f8f8f8;
      border-radius: 0.8rem;
      font-size: 2.6rem;
      padding-left: 5rem;
      margin-right: 4rem;
    }
    .choose {
      width: 23.4rem;
      height: 8rem;
      display: flex;
      align-items: center;
      color: #666666;
      background: #f8f8f8;
      font-size: 2.6rem;
      border-radius: 0.8rem;
      padding-left: 4.1rem;
      margin-right: 4.1rem;
      position: relative;
      // margin-right: 16.4rem;

      img {
        width: 2.2rem;
        height: 1.4rem;
        position: absolute;
        right: 2.8rem;
      }
    }
    .search_btn {
      width: 19.4rem;
      height: 6rem;
      background: #1588F5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7.9rem;
    }
    .reset_btn {
      width: 19.4rem;
      height: 6rem;
      background: #ffffff;
      font-size: 2.6rem;
      font-weight: 400;
      color: #1588F5;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7.9rem;
      border: 0.1rem solid #e5e5e5;
    }
    .library {
      width: 17.7rem;
      height: 6rem;
      border: 0.1rem solid #e5e5e5;
      font-size: 2.6rem;
      font-weight: 400;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4.3rem;
    }
  }
  .list_page {
    // height: 64rem;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .List_box {
    // margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // width: 192rem;
    height: 8rem;
    font-size: 2.6rem;
    font-weight: bold;
    color: #333333;
    border-bottom: 0.1rem solid #e5e5e5;
    // text-align: left;

    .box_wh {
      width: 25%;
      font-size: 2.6rem;
      font-weight: 400;
      color: #333333;
      line-height: 3.7rem;
    }
  }
  .total {
    // width: 192rem;
    height: 10.5rem;
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    border-top: 1px solid #e5e5e5;
    font-size: 2.8rem;
    font-weight: 500;
    color: #000000;
    line-height: 4rem;
    text-align: left;
    // padding-left: 11.6rem;
    // padding-right: 20rem;
    padding-top: 3rem;

    span {
      margin-left: 1.6rem;
      font-weight: bold;
      color: #1588F5;
    }
  }
  /deep/.van-pagination__item--active {
    color: #fff !important;
    background-color: #1588F5 !important;
  }
  /deep/.van-pagination__item {
    color: #1588F5;
  }
  /deep/.van-picker__cancel {
    font-size: 2.6rem !important;
  }
  /deep/.van-picker__title {
    font-size: 2.6rem !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
  /deep/.van-picker__confirm {
    font-size: 2.6rem !important;
    color: #1588F5;
  }
  /deep/.van-ellipsis {
    font-size: 2.6rem !important;
  }
  .bg_f8 {
    background: #f8f8f8;
  }
}
</style>
